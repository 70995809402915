.footer-container
{
    background-color: #262626;
    padding-top: 30px;
    color: white;
}

.footer-container .line-seprator
{
    border: 1px solid rgba(255, 255, 255, 0.59);
    /* width: 95%; */
    margin:20px auto;
}

/* Upper container */

.footer-container .upper-newsletter-container
{
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;
}

.footer-container .upper-newsletter-container .text-container
{
    display: flex;
    align-items: center;
    gap: 14px;
}

.footer-container .upper-newsletter-container .text-container .newsletter-logo
{
    padding: 30px;
    background-color:white ;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-container .upper-newsletter-container .newsletter-logo img{
    width: 50px;
    height: 50px;
}
.footer-container .upper-newsletter-container .newsletter-main-form
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.footer-container .upper-newsletter-container .newsletter-form
{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 15px;
}
.footer-container .upper-newsletter-container .newsletter-form input
{
    width:20em;
    border: none;
    margin-left: 10px;
    padding: 8px;
    height: 100%;
    border-radius: 15px;
}
.footer-container .upper-newsletter-container .newsletter-form input:focus
{
    outline: none;
}
.footer-container .upper-newsletter-container .newsletter-form button
{
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    /* position: relative;
    right: 10%; */
    border-radius: 15px;
    background-color: #7BBA4B;
    color: white;
    padding:13px 15px;
    font-weight: 700;
    font-size: 1.25rem;
    transition: .3s;
}

.footer-container .upper-newsletter-container .newsletter-form button img{
    width: 15px;
}

.footer-container .upper-newsletter-container .newsletter-form button:hover{
    cursor: pointer;
    background-color: #69c324;
}

.footer-container .upper-newsletter-container .text-container .text-content p
{
    font-size: 16px;
    color: #CCCCCC;
}
.footer-container .upper-newsletter-container .text-container .text-content h3
{
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 1.3rem;
    color: white;
}

.footer-container .upper-newsletter-container .text-container{
    width: 40%;
}

/* Middle link Container */

.footer-container .middle-links-container
{
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;
}

.footer-container .middle-links-container .left
{
    width: 20%;
}

.footer-container .middle-links-container .right
{
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
}

.footer-container .middle-links-container .right .right-upper
{
    display: flex;
    font-size: normal;
    padding-left: 0;
}
.footer-container .middle-links-container .right .right-upper li
{
    list-style: none;
    cursor: pointer;
    font-size: normal;
}
.footer-container .middle-links-container .right .right-upper li:hover{
    color: #CCCCCC;
}
.footer-container .middle-links-container .right .right-bottom
{
    gap: 30px;
    row-gap: 25px;
    padding-left: 0;
}
.footer-container .middle-links-container .right .right-bottom li
{
    display: flex;
    align-items: center;
    gap: 5px;
}
.footer-container .middle-links-container .right .right-bottom li img
{
    background-color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 5px;
}

.footer-container .middle-links-container .right .right-bottom li a
{
    color: white;
    text-decoration: none;
}
.footer-container .middle-links-container .right .right-bottom li
{
    list-style: none;
    cursor: pointer;
}
.footer-container .middle-links-container .right .right-bottom li a:hover{
    color: #CCCCCC;
}

/* Lower Container */

.footer-container .lower-container
{
    
    background-color: white;
    padding:15px 0px;
    color: #9B9B9B;
}

.footer-container .right .lower-link-list
{
    display: flex;
    margin-bottom: 0;
    gap: 15px;
    padding-left: 0;
}
.footer-container .right .lower-link-list li
{
    list-style: none;
}
.footer-container .right .lower-link-list li img
{
   width: 25px;
}
.footer-container .right .lower-link-list li img:hover
{
   cursor: pointer;
}
.footer-container .lower-container small a 
{
    text-decoration: none;
    color: #9B9B9B;
}

.footer-container .page-links .main-anchor
{
    font-size: 20px;
    color: #7bba4b;
}
.footer-container .page-links .anchor
{
    text-decoration: none;
    color: white;
    cursor: pointer;
}
.footer-container .page-links .active
{
    color: #7BBA4B;
}
.footer-container .footer-social-icon
{
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.footer-container .upper-newsletter-container .newsletter-form .subscribe-btn-text
{
    font-size:18px;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
}
@media(max-width : 1050px)
{
    .footer-container .upper-newsletter-container
    {
        flex-direction: column;
    }
    .footer-container .upper-newsletter-container .text-container
    {
        flex-direction: column;
        text-align: center;
    }
    .footer-container .upper-newsletter-container .text-container,.newsletter-form
    {
        margin: auto;
        margin-bottom: 15px;
    }
    .footer-container .middle-links-container .right .right-upper
    {
        padding: 0;
        font-size: small;
    }
    .footer-container .upper-newsletter-container .newsletter-form button
    {
        right: 50px;
    }
    
}
@media(max-width:760px)
{
    .footer-container .middle-links-container
    {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 730px) {
    
    
    .footer-container .upper-newsletter-container .text-container 
    {
        width: 100%;

    }
    .footer-container .upper-newsletter-container .newsletter-form
    {
        justify-content: center;
    }
    .footer-container .middle-links-container .right
    {
        width: 90%;
    }
    .footer-container .middle-links-container .left
    {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 20px;
    }
    .footer-container .upper-newsletter-container .newsletter-form
    {
        width: 70%;
        position: relative;
    }
    .footer-container .upper-newsletter-container .newsletter-form input
    {
        width: 100%;
        padding: 7px 10px;
        margin: auto;
        margin-left: 10px;
    }
    .footer-container .upper-newsletter-container .newsletter-form button
    {
        padding: 10px;
        position: absolute;
        right:0;
    }
    .footer-container .middle-links-container .right .right-bottom
    {
        margin-top: 20px;
    }
    
    .footer-container .lower-container
    {
        /* padding-left: 10px;
        padding-right: 10px; */
    }

    .footer-container .upper-newsletter-container .newsletter-form .subscribe-btn-text
    {
        display: none;
    }
}