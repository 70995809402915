.TeamHelpCall-container
{

}
.TeamHelpCall-container .color-slate-cont
{
    background: radial-gradient(farthest-corner at 50% 50%, 
#76b1491b 0%, 
rgba(124, 186, 76, 0.076) 56%, 
rgba(123,186,76,0 ) 100%, 
rgba(255,255,255,0 ) 100%);
}

/* .TeamHelpCall-container .person-img
{
    transform: translateY(-40px);
} */

@media(max-width:992px)
{
    .TeamHelpCall-container .color-slate-cont
    {
        background:transparent;
    }
    .TeamHelpCall-container .person-img
    {
        transform: translateY(0px);
    }
    .TeamHelpCall-container .row-cont
    {
        flex-direction: column-reverse;
    }
}