.FormContainer-container
{

}

.FormContainer-container .upper-form
{
    border-radius: 7px;
filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.16));
background-color: #ffffff;
}

.FormContainer-container .upper-form-img
{
    border-right: 3px solid #96CDE9;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.FormContainer-container .green-anchor
{
    color: #7BBA4C;
}
.FormContainer-container .form-list
{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.FormContainer-container .bottom-form-img
{
    border-radius: 7px;
filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.046));
background-color: #ffffff;

}

.FormContainer-container .swiper-slide 
{
    display: flex;
    align-items: center;
}

@media(max-width:992px) {
    .FormContainer-container .upper-form-img
    {
        border-right: 0px solid #96CDE9;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}