.video-content-section
{
    background:transparent;
}

.video-content-section .text-content{
  /* background : rgb(235, 220, 227);
  background: radial-gradient(circle, rgb(243, 231, 236) 0%,rgb(249, 243, 246) 35%, rgba(252,252,252,1) 56%, rgba(255,255,255,1) 100%); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.video-content-section .container-space
{
  margin: auto;
}

.video-content-section .paddLeft
{
    padding-left: 5%;
}

.video-content-section .line-seprator
{
    border: 1px solid #c1c0c0;
    width: 100%;
    margin: auto;

}

/* .video-content-section .bg-shade-color
{
  background-color: #F8F8F8;
  margin-bottom: 80px;
}
.video-content-section .bg-shade-color .low-cont
{
  transform: translateY(80px);
} */