.Testimonials-container
{
    /* background-color: #fbfbfb; */
    background-color: white;
    border-bottom-left-radius:50px ;
    border-bottom-right-radius: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding-left: 3em;
    padding-right: 3em;
    position: relative;
    z-index: 100;
}

.Testimonials-container .text-content
{
    color: black;
    width: 80%;
}

.Testimonials-container .testimonial-card
{

    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

}
.Testimonials-container .testimonial-card .quote-img
{
    width: 3em;
}

.Testimonials-container .testimonial-card p
{
    line-height: 1.5;
    margin: 15px 0;
    white-space: normal;
}

.Testimonials-container .testimonial-card .testimonial-card-info
{
    padding: 2px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Testimonials-container .testimonial-card .testimonial-card-info .testimonial-card-control
{
    display: flex;
    gap: 5px;
    
    
}
.Testimonials-container .testimonial-card .testimonial-card-info .control-btn
{
    border-radius: 9px;
    filter: drop-shadow(0px 3px 15px rgba(0,0,0,0.07));
    background-color: #ffffff;
    padding: 5px 8px;
}
.Testimonials-container .testimonial-card .testimonial-card-info .control-btn img
{
    width: 15px;
}

.Testimonials-container .profile-info
{
    display: flex;
    align-items: center;
    gap: 15px;
}

.Testimonials-container .profile-info .profile-info-text
{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
}

.Testimonials-container .profile-info .profile-info-text img
{
   width: 100px;
}

.Testimonials-container .text-content .read-more-btn
{
    padding: .4rem 1.3rem;
}

.Testimonials-container .profile-info img{
    border-radius: 50%;
    width: 60px;
}
.Testimonials-container .profile-info h5{
   font-size: 1.3rem;
   font-weight: 600;
   letter-spacing: 1px;
}


@media (max-width: 730px) {
    .Testimonials-container
    {
        flex-direction: column;
        height: auto;
    }
    .Testimonials-container .text-content
    {
        width: 80%;
    }
}