.news_container
{
    display: flex;
    align-items: center;
    padding: 30px 0;
}

.news_img_container
{
    display: flex;
    justify-content: center;
}
.news_img_container img
{
   max-height: 400px;
}
/* 
.news_container .sub-heading
{
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    letter-spacing: 2.8px;
    color: #DB831C;
} */

/* .news_container .body-heading
{
    color: #2D2D2D;
}
.news_container .body-paragraph
{
    color: #373737;
} */

.text_content
{
    padding: 10px;
}

.latest_news_img
{
    border-radius: 17px;
}

.blog_news_logo
{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}
.blog_news_logo img{
    width: 50px;
}
.blog_news_logo_text h6
{
    font-size: .9rem;
    font-weight: 600;
    color: white;
}
@media(max-width:700px) {
    .news_container{
        flex-direction: column;
    }
    .news_img_container
    {
        width: 100%;
    }
    .text_content
    {
        width: 100%;
        text-align: center;
    }   
    .text_content .blog_news_logo
    {
        justify-content: center;
    }
}