.GalleryGrid-container
{
    transform: translateY(-90px);
}

.GalleryGrid-container .img-card
{
    height: 350px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 35px;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.23);
    border-radius: 15px;
}