.common_hero_section {
  color: black;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
 
  width: 100%;
  height: 40rem;
  z-index: 0;
}

.common_hero_section .pre-header
{
  /* background-color: #292929; */
  /* color: white; */
}
.common_hero_section .pre-header-green
{
  background-color: #7bba4c;
  color: white;
  text-align: center;
}

.common_hero_section .nav-title
{
  font-size: 14px;
  color: #7b4636;
  margin-bottom: 3px;
}
.common_hero_section .upper-nav-txt
{
  font-size: 14px;
  color: #414141;
}

/* .common_hero_section .bg-effect
{
  background: rgba(167, 166, 166, 0.367);
  background: linear-gradient(0deg, rgba(91, 91, 91, 0.445) 0%, rgba(76, 75, 75, 0.377) 100%);
} */
.common_hero_section .bg-effect
{
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient( hsla(220, 72%, 14%, 0.285), hsla(220, 71%, 14%, 0.051),#0818392f,#09193A);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index:-1 ;
}

.common_hero_section .hero-container
{
  border-radius: 20px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.common_hero_section .wrapper
{
  
  border-radius: 20px;
  height: 100%;
}

.bg-video
{
  position: relative;
  width: 100%;
  height: 100vh;
  /* overflow: hidden; */
  background-color: rgba(255, 255, 255, 0.536);
}

.bg-video .bg-video-mp{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.common_hero_section .hero_content_wrapper {
  display: flex;
  padding-left: 0%;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 85%;
}



.common_hero_section .hero_content_wrapper .hero-btn
{
  padding: 0.6rem 2.3rem;
  font-size: 14px;
  background: transparent;
  border: 1px solid white;
  font-weight: 700;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 29px;
  transition: .3s;
}
.common_hero_section .hero_content_wrapper .hero-btn:hover
{
  background: white;
  border: 1px solid white;
  color: #7BBA4B;
}
.common_hero_section .heading-color-change
{
  color: #7BBA4B;

}
.common_hero_section .service-form
{
  background-color: rgb(255, 255, 255, 0.298);
  backdrop-filter: blur(10px);
  border: 2px solid #ebebeb;
  border-radius: 12px;
}
.common_hero_section .service-form .service-from-btn
{
  margin-top: 30px;
}

.common_hero_section .service-form textarea{
  resize: none;
}

.common_hero_section .service-form input,
.common_hero_section .service-form textarea
{
  border: none;
  border-bottom:1px solid #5a5959;
  background: transparent;
  margin-top: 20px;
  color:#5a5959;
  padding: 10px 10px;

}
.common_hero_section .service-form textarea
{
  border:1px solid #5a5959;
  border-radius: 5px;
}

.common_hero_section .service-form input::placeholder,
.common_hero_section .service-form textarea::placeholder
{
  color:#5a5959;

}
.common_hero_section .service-form input:focus,
.common_hero_section .service-form textarea:focus
{
  outline: none;
}

.common_hero_section .hero-text-content-limited-width
{
  width: 50%;
}
.common_hero_section .hero-text-content .body-paragraph 
{
  /* width: 50%; */
  /* text-align: center; */
}
@media(max-width :760px)
{
  .h-cus-auto
  {
    height: auto!important;
  }
}

@media(max-width : 660px)
{
  .common_hero_section .hero-text-content .body-paragraph 
  {
    width : 100%;
  }
  .common_hero_section .hero-text-content
{
  width: 100%;
}
  .common_hero_section .hero-text-content-limited-width
{
  width: 100%;
}
}

@media(max-width : 400px)
{
  .common_hero_section .hero_content_wrapper .hero-btn
  {
    padding: 0.6rem 1.8rem;
    font-size: 15px;
  }
}