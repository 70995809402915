.blog-container
{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */

}
.blog-container .card-box
{
    color: black;
    padding:  10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.097);
    border-radius: 15px;
    cursor: pointer;
    height: 100%;
}

.blog-container .swiper-slide
{

    height: 100%;
}

.blog-container .card-box .card-text-content
{
    width: 93%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto;
}

.blog-container .card-box h5{
    color :#373737;
    font-size: .9rem;
    font-weight: 700;
    margin-top: 10px;
}
.blog-container .card-box p{
    color :#747474;
    font-size: .78rem;
}


.blog-container .card-box a,
.blog-container .card-box .read-more-anchor
{
    color: #8FB569;
    text-decoration: none;
    transition: .3s;
    margin-bottom: 10px;
}

.blog-container .card-box a:hover,
.blog-container .card-box .read-more-anchor:hover{
    cursor: pointer;
    color: #8cdd3b;
}

.blog-container .card-box img{
    width: 95%;
    margin:5% auto;
    margin-bottom: 10px;
    border-radius: 8px;
}

.blog-container .card-box .top-links
{
    display: flex;
    justify-content: start;
    gap: 15px;
}

.blog-container .card-box .top-links img{
    width: 15px;
}
.blog-container .card-box .top-links p{
    font-size: .8rem;

}

.blog-container .blog-text-content
{
    margin-bottom: 40px;
}

.blog-container .blog-upper-content
{
}

.blog-container .blog-upper-content .blog-text-content
{
    
}
.blog-container .blog-upper-content .blog-btn
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.blog-container .blog-upper-content .blog-btn button
{
    padding: 7px 30px;
}

.blog-container .card-list
{
    margin: auto;
    width: 90%;
}

.blog-container .blue-btn 
{
    margin-top: 40px;
}



@media(max-width:768px)
{
    .blog-container .blog-upper-content .blog-btn
    {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}