.WorkingHour-container
{
    background: #8cc95f;
    background: linear-gradient(59deg, 
    #8cc95f 0.00%, 
    #51951f 100.00%);
}
.WorkingHour-container .schedule-box
{
    border-radius: 13px;
    background-color: #ffffff;
}