.WhyUsBanner-container
{

}


.WhyUsBanner-container .card-box
{
    border-radius: 15px;
    filter: drop-shadow(0px 3px 5px rgba(0,0,0,0.16));
    background-color: #ffffff;
    /* width: 90%; */
    padding: 20px 10px;
    height: 100%;
}

.WhyUsBanner-container .card-icon
{
    width: 100px;
    height: 100px;
}