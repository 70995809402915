.ServicesCarasoul-container
{
    
background-color: #fff;
}

.ServicesCarasoul-container .main-heading
{
  text-align: center;
  width: 50%;
  margin: auto;
}
.ServicesCarasoul-container .main-heading .heading-color-change
{
  color: #E1A92A;
}



.ServicesCarasoul-container .service-card
{
    /* width: 100%; */
        
    border-radius: 15px;
    filter: drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.104));
    background-color: #ffffff;
    height: 100%;
}
.ServicesCarasoul-container .service-card .service-card-info,
.ServicesCarasoul-container .service-card .profile-info
{
    /* height: 100%; */
    
}
.ServicesCarasoul-container .service-card .review-text
{
    color:white;
    font-size: 14px!important;
}


.ServicesCarasoul-container .genral-btn
{
    background-color: #E1A92A;
    width: fit-content;
    margin: auto;
}

.ServicesCarasoul-container .swiper-button-prev,
.ServicesCarasoul-container .swiper-button-next
{
    color: #848484!important;
}

.ServicesCarasoul-container .swiper-button-prev
{
    position: absolute;
    top: 45%;
    left: 0;
    z-index: 10;
    cursor: pointer;

}
.ServicesCarasoul-container .swiper-button-next
{
    position: absolute;
    top: 45%;
    z-index: 10;
    right: 0;
    cursor: pointer;

}


.ServicesCarasoul-container .swiper-slide
{
    height: auto;
}

.ServicesCarasoul-container .card-anchor
{
    cursor: pointer;
    text-decoration: underline;
    color: #8fb569;
}
.ServicesCarasoul-container .card-round-icon
{
    width: 100px;
    height: 100px;
    margin: auto;
}

.ServicesCarasoul-container .swiper-wrapper
{
    margin-bottom: 60px;
}
.ServicesCarasoul-container .swiper-pagination .swiper-pagination-bullet-active
{
    width: 30px!important;
    border-radius: 5px;
    background-color: #7BBA4B;
}
.ServicesCarasoul-container .swiper-pagination .swiper-pagination-bullet
{
    width: 10px;
    border-radius: 5px;
}

@media(max-width:660px)
{
    .ServicesCarasoul-container .main-heading
{
  width: 80%;
}
}