.TeamCards-container
{

}
.TeamCards-container .team-card-box
{
    width: 100%;
        
    border-radius: 29px;
    filter: drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.104));
    background-color: #ffffff;
    height: 100%;
}
.TeamCards-container .team-card-box .team-card-box-info,
.TeamCards-container .team-card-box .profile-info
{
    /* height: 100%; */
    
}
.TeamCards-container .team-card-box .review-text
{
    color:white;
    font-size: 14px!important;
}

.TeamCards-container .team-card-box .card-anchor
{
    cursor: pointer;
    text-decoration: underline;
    color: #8fb569;
}
.TeamCards-container .team-card-box .card-round-icon
{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    padding: 5px;
    background-color: #8fb569;
    margin: auto;
}