.pre-header-container .pre-header
{
  /* background-color: #292929; */
  /* color: white; */
}
.pre-header-container .pre-header-green
{
  background-color: #7bba4c;
  color: white;
  text-align: center;
}

.pre-header-container .nav-title
{
  font-size: 14px;
  color: #7b4636;
  margin-bottom: 3px;
}
.pre-header-container .upper-nav-txt
{
  font-size: 14px;
  color: #414141;
}