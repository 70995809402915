.header {
    /* background: transparent; */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2));
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header .header-wrapper {
    
    width: 100%;
    margin: auto;
}

.header .header-wrapper .navbar .navbar-container {
    width: 100%;
    border-top: 2px solid #7a7a7a47;
}
.header .header-wrapper .navbar .navbar-container .nav-logo{
  max-width: 200px;
  height: 47px;
}

.header .main-nav {
    list-style-type: none;
    margin: 0;
}

.header .nav-links,
.logo {
    text-decoration: none;
    /* color:  #ffffff; */
    /* font-size: .9rem; */
}

/* .header .nav-links:active,
.logo:active {
    color: #ffffff;
    font-weight: 500;
} */

/* .header .nav-links:focus,
.logo:focus {
    color: #ffffff;
    font-weight: 500;
}

.header .main-nav li {
    text-align: center;
    color: #ffffff;
} */
.header .nav-list .nav-links
{
  /* font-size: 15px; */
  /* font-weight: 500; */
    cursor: pointer;
    /* color: white; */
    transition: .3s;
    text-transform: capitalize;
}
.header .nav-list .nav-links:hover
{
  color: #7bba4c;
}

.header .extras-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.header .responsive-popup-menu
{
    z-index: 100;
    position: absolute;
    top: 80px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    color: black;
}

.header .responsive-menu-btn img
{
    width: 25px;
    height: 25px;
}




.header .extras-btns .blue-btn img {
    width: 17px;
    margin-right: 3px;
}

.header .resp-nav-list .nav-links 
{
    color: black;
}

.header .resp-nav-list li{
    list-style: none;
}
.header .resp-nav-list
{
    width: 100%;
}


/* Dropdown css */

.navigation-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-trigger {
    cursor: pointer;
    /* color: white; */
  }
  
  .dropdown-content {
    position: absolute;
    top: 130%;
    left: -32%;
    background-color: white;
    min-width: 100px;
    width: max-content;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px;
    padding: 10px 20px;
     display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  .dropdown-content .nav-links{
    color: black!important;
    list-style: none;
    text-decoration: none;
  }
  .dropdown-content .nav-links:hover{
    color: #7bba4c!important;
  }
  
  
  /* Custom CSS Animation */
  .dropdown-content-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .dropdown-content-enter-active {
    display: block;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .dropdown-content-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .dropdown-content-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
  }

  .header .arrow-icon
  {
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
  
  .header .rotate-icon {
    animation: rotate180 .3s forwards;
  }

  .header .search-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
  }

  .header .search-input {
    padding-left: 30px; /* Adjust according to the width of the icon */
    border: none;
    height: 40px;
    width: auto; /* Adjust width as needed */
    font-size: 16px;
  }
  .header .search-input:focus
  {
    outline: none;
  }

  .header .search-icon {
    position: relative;
    left: 10px; /* Adjust position as needed */
    height: 20px; /* Adjust size as needed */
    fill: #666; /* Adjust color as needed */
  }


  
  @keyframes rotate180 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  
  /* Return to original position */
  .header .rotate-back {
    animation: rotateBack .1s forwards;
  }
  
  @keyframes rotateBack {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  
  .header .nav-list .none-delay{
    display: none;
  }
  .header .nav-list .open{
    display: flex;
  }


  
@media(max-width : 1200px)
{
    /* .header .nav-list .nav-links
    {
        font-size: .7rem;
        margin-right: 5px;
        margin-left: 5px;
        font-weight: 500;
    }
    .header .arrow-icon{
      width: 10px;
      height: 10px;
      margin-left: 5px;
    } */
}