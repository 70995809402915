.KeyFeatureText-container
{
    
}
.KeyFeatureText-container .main-box
{
    
    border-radius: 15px;
    filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.16));
    background-color: #ffffff;
}
.KeyFeatureText-container .emergency-btn{
    border-radius: 15px;
    filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.16));
    background-color: #ffffff;
    padding-left: 30px;
}
.KeyFeatureText-container .emergency-btn .icon
{
    width: 90px;
    height: 60px;
}
.KeyFeatureText-container .tab-box
{
    padding: 5px;
    cursor: pointer;
    background-color: transparent;
    text-align: start;
}
.KeyFeatureText-container .tab-box:nth-child(1)
{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-top: 18px;
}
.KeyFeatureText-container .tab-box:last-child
{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-bottom: 18px;
}
.KeyFeatureText-container .tab-box
{
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 20px;
}
.KeyFeatureText-container .active-tab
{
    

    background-color: #7BBA4B!important;
}

.KeyFeatureText-container .active-title
{

    color: white!important;
}
.KeyFeatureText-container .main-box .body-heading
{
    /* width: fit-content; */
    padding: 8px;
    font-size: 16px!important;
    border-radius: 3px;
}
.KeyFeatureText-container .map-size
{
    width: 100%;
    height: 500px;
}

.KeyFeatureText-container .cont
{
   padding-left: 20px;
}

/* .KeyFeatureText-container .cont
{
    height:600px;
    overflow-y: auto;
    overflow-x: hidden;
}
.KeyFeatureText-container .cont {

    overflow-y: scroll; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
} */

.KeyFeatureText-container .cont::-webkit-scrollbar {
    display: none;
}

.KeyFeatureText-container .Acupuncture-container a ,
.KeyFeatureText-container .Chiropractic-container a ,
.KeyFeatureText-container .clt-container a 
{
    color: #7BBA4B!important;
}

.KeyFeatureText-container .Chiropractic-container .elementor-icon-list-items li,
.KeyFeatureText-container .ca-container .no-bullet-list li,
.KeyFeatureText-container .con-t-container .no-bullet-list li,
.KeyFeatureText-container .cony-container .no-bullet-list li
{
    list-style: none;
}

.KeyFeatureText-container .custom-green-bullet-list{
        list-style-image: url('../../../public/images/icons/hero-bullets.png'); 
        padding-left: 20px; 
       
}
.KeyFeatureText-container .custom-green-bullet-list li{
        padding-left: 10px;
}

@media(max-width:1200px)
{
    .KeyFeatureText-container .cont
    {
    padding-left: 5px;
    }
}


@media(max-width:600px)
{
    .KeyFeatureText-container .main-box .body-heading
{
    font-size: 15px!important;
}
}