.ServiceCard-container
{

}

.ServiceCard-container .service-card-box
{
    width: 100%;
        
    border-radius: 15px;
    filter: drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.104));
    background-color: #ffffff;
    height: 100%;
}
.ServiceCard-container .service-card-box .service-card-box-info,
.ServiceCard-container .service-card-box .profile-info
{
    /* height: 100%; */
    
}
.ServiceCard-container .service-card-box .review-text
{
    color:white;
    font-size: 14px!important;
}

.ServiceCard-container .service-card-box .card-anchor
{
    cursor: pointer;
    text-decoration: underline;
    color: #8fb569;
}
.ServiceCard-container .service-card-box .card-round-icon
{
    width: 100px;
    height: 100px;
    margin: auto;
}