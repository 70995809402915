* {
  margin: 0; 
  padding: 0;
  scroll-behavior: smooth; 
  font-family: "Rubik", sans-serif!important;
}



.standard-margin-space {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.standard-padding-space {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.color-green
{
  color: #66a13a;
}

.body-heading, .hero-section-heading { 
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 1.5px;
}
.body-heading span, .hero-section-heading span{ 
  font-weight: 600;
  text-transform: capitalize;
}

.body-heading {
  color: #303030;
   font-size: 50px; 
   font-weight: 600;
  }

.hero-section-heading  
{ 
  font-size: 55px;
  font-weight: 600;
  /* color: white;  */
  /* letter-spacing: 4px;  */
}
.hero-section-heading span {

 }
  
.body-paragraph { 
  font-weight: 400;
  font-size: 16px;
  color: #414141;
}

.nav-txt
{
  font-size: 14px;
  color: #414141;
  font-weight: 400;
}

  

.genral-btn{ 
  padding: 0.5rem 2rem;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border-radius: 5px;
  filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));
  background-image: linear-gradient(103deg, #7bba4c 0%, #66a13a 100%);
  border: none;
  transition: .3s;
}
.genral-btn:hover{ 
  background-image: linear-gradient(78deg, #7bba4c 30%, #66a13a 100%);
}

.hero-sub-heading
{
  font-size: 25px;
  font-weight: 600;
  color: white;
}
.sub-heading
{
  font-size: 25px;
  color: #7bba4b;
  font-weight: 500;
  text-transform: capitalize;

}

.genral-btn span {
  margin-right: .4rem;
}

@media (max-width: 1250px) {
  .body-heading  {
    font-size: 35px;
  }

  .hero-section-heading {
    font-size: 48px;
  }
}

@media (max-width: 460px) {
  .body-heading  {
    font-size: 30px;
  }
 
  .hero-section-heading  {
    font-size: 38px;
  }

  .body-paragraph {
    font-size: 15px;
  }
}

@media (max-width: 380px) {
  .body-heading  {
    font-size: 27px;
  }

  .hero-section-heading  {
    font-size: 32px;
  }

  .body-paragraph {
    font-size: 14px;
  }

  .sub-heading {  
    /* font-size: 12px;  */
  } 
}


/* Width, color, and radius for the track */
::-webkit-scrollbar {
  width: 12px;
}

::-moz-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7bba4c;
  transition: .3s;
  border-radius: 3px;
}

::-moz-scrollbar-thumb {
  background: #7bba4c;
  transition: .3s;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7bba4c;
}

::-moz-scrollbar-thumb:hover {
  background: #7bba4c;
}

/* Track */
::-webkit-scrollbar-track {
  transition: .3s;
  background: #c6d8b8;
}

::-moz-scrollbar-track {
  transition: .3s;
  background: #c6d8b8;
}
/* Track on hover */
::-webkit-scrollbar-track:hover {
  transition: .3s;
  background: #c6d8b8;
}

::-moz-scrollbar-track:hover {
  transition: .3s;
  background: #c6d8b8;
}

/* Handle when dragging */
::-webkit-scrollbar-thumb:active {
  background: #7bba4c;
}

::-moz-scrollbar-thumb:active {
  background: #7bba4c;
}

/* Track when dragging */
::-webkit-scrollbar-track:active {
  transition: .3s;
  background: #c6d8b8;
}

::-moz-scrollbar-track:active {
  transition: .3s;
  background: #c6d8b8;
}
